<template>
   <div class="page_section" v-if="news.bySlug">
      <MobileTitle :title="news.bySlug.title"/>
      <Banner :content="{
                    title: __t('news.single_news_title'),
                    image: settings.all.news_default_image,
                    smallBanner: false
                }"/>
      <div class="container view-job-wrapper">
         <div class="row">
            <div class="col-12">
               <Breadcrumb :links="breadcrumb" class="mb30"/>
            </div>
            <div class="col-md-8 content-wrapper">
               <h1 class="title" v-text="news && news.bySlug ? news.bySlug.title : '' "></h1>
               <div class="type-title" v-text="news && news.bySlug ? news.bySlug.teaser : ''"></div>
               <!--                    <div class="subtitle"></div>-->
               <div class="content" v-html="news && news.bySlug ? news.bySlug.content : ''"></div>
            </div>
         </div>
      </div>

      <Footer/>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import {config} from "../../config";
   import Footer from "../_common/Footer";
   import Breadcrumb from "../_common/Breadcrumb";
   import MobileTitle from "../_common/MobileTitle";
   import Banner from "../_content_elements/Banner";

   export default {
      name: "News",
      components: {
         Footer,
         // ContentElements,
         MobileTitle,
         Breadcrumb,
         Banner
      },
      computed: {
         ...mapState([
            'news',
            'page',
            'region',
            'settings',
         ]),
         breadcrumb() {
            if (this.page.byID && this.news.bySlug) {
               return [
                  {
                     title: this.__t('homepage'),
                     path: '/',
                     active: false
                  },
                  {
                     title: this.page?.byID?.nav_title?.title,
                     path: '/' + this.page?.byID?.slug,
                     active: false
                  },
                  {
                     title: this.news?.bySlug?.title,
                     path: '/' + this.$route?.path,
                     active: true
                  },
               ];
            } else {
               return [
                  {
                     title: this.__t('homepage'),
                     path: '/',
                     active: false
                  }
               ];
            }
         }
      },
      created() {
         this.$store.dispatch('news/getBySlug', this.$route?.params?.slug)
         this.$store.dispatch('page/getByID', config?.serviceAndContactIds[this.region?.currentRegion?.id])
      },
      beforeDestroy() {
         this.$store.commit('page/setByID', {})
         this.$store.commit('news/setBySlug', {})
      }
   }
</script>

<style lang="scss" scoped>
</style>
